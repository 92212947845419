import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class LoyaltyAuthModule extends VuexModule {
    token?: string;

    @Mutation
    SET_TOKEN({ token }: SetToken): void {
        this.token = token;
    }

    @Mutation
    CLEAR_TOKEN(): void {
        this.token = undefined;
    }
}

type ModulePrefix = 'loyaltyAuth';

export type LoyaltyAuthModuleMethod = SetToken | ClearToken;

interface SetToken {
    type: `${ModulePrefix}/SET_TOKEN`;
    token: string;
}

interface ClearToken {
    type: `${ModulePrefix}/CLEAR_TOKEN`;
}
