
import { Vue, Options } from 'vue-class-component';
import { DateString, LoyaltyVIPTier, LoyaltyVIPProgramViewPublic, LoyaltyCustomerViewPublic } from '@okendo/reviews-common';

import store from '@/store';
import { getCurrentTier, getCurrentTierIndex } from '@/utils/loyaltyVipTiersUtil';
import LoyaltyBadge from './LoyaltyBadge.vue';
import LoyaltyModule from './LoyaltyModule.vue';
import type { ResourceState } from '@/store/modules/loyalty';
import { exampleLoyaltyMember } from '@/utils/loyaltyUtils';
import { StoreMethod } from '@/store/storeTypings';
import { formatDate } from '@/utils/dateUtil';

@Options({
    components: {
        LoyaltyBadge,
        LoyaltyModule
    }
})
export default class LoyaltyVipTiers extends Vue {
    override async mounted(): Promise<void> {
        if (this.isPreviewMode) {
            await store.dispatch<StoreMethod>({
                type: 'loyalty/GET_VIP_TIERS'
            });
        }
    }

    get vipTiersState(): ResourceState {
        return store.state.loyalty.vipTiersState;
    }

    get showVipTiersModule(): boolean {
        return this.isPreviewMode || !!(this.vipTiersState !== 'error' && this.vipTiersState !== 'unloaded' && this.member);
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get vipTiers(): LoyaltyVIPProgramViewPublic.VIPTier[] {
        return store.state.loyalty.vipTiers;
    }

    get filteredVipTiers(): LoyaltyVIPProgramViewPublic.VIPTier[] {
        const currentTier = this.vipTiers[this.currentTierIndex];
        const previousTier = this.vipTiers[this.currentTierIndex - 1];
        const nextTier = this.vipTiers[this.currentTierIndex + 1];
        const hasReachedCurrentTierSpend = this.totalSpend >= (currentTier?.entryRequirement.spend ?? 0);

        return !hasReachedCurrentTierSpend
            ? [...(previousTier ? [previousTier] : []), ...(currentTier ? [currentTier] : [])]
            : [...(currentTier ? [currentTier] : []), ...(nextTier ? [nextTier] : [])];
    }

    get member(): LoyaltyCustomerViewPublic | null {
        return this.isPreviewMode ? exampleLoyaltyMember : store.state.loyalty.member;
    }

    get loyaltyPageUrl(): string {
        if (store.state.loyalty.generalSettings?.storeLoyaltyPagePath) {
            const loyaltyPageUrl = new URL(store.state.loyalty.generalSettings.storeLoyaltyPagePath, this.storeUrl);
            return loyaltyPageUrl.href;
        }

        return `${this.storeUrl}/?okendo_loyalty_open=true&okendo_loyalty_view=vip-tiers`;
    }

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get totalSpend(): number {
        return store.state.loyalty.memberSpend?.customerSpend ?? 0;
    }

    get dateSpendFrom(): DateString | undefined {
        return store.state.loyalty.memberSpend?.dateFrom;
    }

    get dateTierExpires(): DateString | undefined {
        return store.state.loyalty.member?.dateTierExpires;
    }

    get currentTierIndex(): number {
        return getCurrentTierIndex();
    }

    get totalSpendTierIndex(): number {
        // The tier they would be placed in based only on current spend
        return this.filteredVipTiers.findIndex((tier, index) => {
            const currentTierSpend = tier.entryRequirement.spend;
            const nextTierSpend = this.filteredVipTiers[index + 1]?.entryRequirement.spend;
            return this.totalSpend >= currentTierSpend && (!nextTierSpend || this.totalSpend < nextTierSpend);
        });
    }

    get progressBarStyle(): { height: string } | undefined {
        const currentTier = this.filteredVipTiers[this.totalSpendTierIndex];
        const nextTier = this.filteredVipTiers[this.totalSpendTierIndex + 1];
        if (!currentTier || !nextTier) {
            return;
        }

        const currentTierSpend = currentTier.entryRequirement.spend;
        const nextTierSpend = nextTier.entryRequirement.spend;
        const percentageProgress = (100 * (this.totalSpend - currentTierSpend)) / (nextTierSpend - currentTierSpend);

        if (percentageProgress > 0) {
            return { height: `${percentageProgress}%` };
        }
    }

    getTierButtonLabel(tierIndex: number): string {
        if (tierIndex === this.currentTierIndex) {
            return this.$t('Tier Perks');
        }

        return this.$t('Shop now');
    }

    get currentTier(): LoyaltyVIPTier | undefined {
        return getCurrentTier();
    }

    get previousTier(): LoyaltyVIPTier | undefined {
        return this.vipTiers[this.currentTierIndex - 1];
    }

    get nextTier(): LoyaltyVIPTier | undefined {
        return this.vipTiers[this.currentTierIndex + 1];
    }

    get promptType(): TierPromptType | undefined {
        if (!this.currentTier) {
            return;
        }

        const hasReachedCurrentTierSpend = this.totalSpend >= this.currentTier.entryRequirement.spend;
        if (hasReachedCurrentTierSpend) {
            return this.nextTier ? 'upgrade' : 'on-highest-tier';
        }
        else {
            return 'maintain';
        }
    }

    get currencySymbol(): string {
        return store.state.subscriber.currencySymbol;
    }

    getTierDescription(tierIndex: number): string | undefined {
        if (tierIndex === this.currentTierIndex) {
            if (this.dateSpendFrom) {
                return this.$t('Spent {currencySymbol}{totalSpend} since {dateSpendFrom}', {
                    currencySymbol: this.currencySymbol,
                    totalSpend: this.totalSpend,
                    dateSpendFrom: formatDate(this.dateSpendFrom)
                });
            }
            else {
                return this.$t('Spent {currencySymbol}{totalSpend}', {
                    currencySymbol: this.currencySymbol,
                    totalSpend: this.totalSpend
                });
            }
        }
        else {
            switch (this.promptType) {
                case 'upgrade':
                    if (!this.nextTier) {
                        return;
                    }
                    // eslint-disable-next-line no-case-declarations
                    const spendToNextTier = this.nextTier.entryRequirement.spend - this.totalSpend;

                    return this.dateTierExpires
                        ? this.$t(`Spend {currencySymbol}{value} more by {date} to reach the {tierName} tier.`, {
                            currencySymbol: this.currencySymbol,
                            value: spendToNextTier,
                            date: formatDate(this.dateTierExpires),
                            tierName: this.nextTier.name
                        })
                        : this.$t(`Spend {currencySymbol}{value} more to reach the {tierName} tier.`, {
                            currencySymbol: this.currencySymbol,
                            value: spendToNextTier,
                            tierName: this.nextTier.name
                        });
                case 'maintain':
                    if (!this.previousTier || !this.currentTier) {
                        return;
                    }
                    // eslint-disable-next-line no-case-declarations
                    const spendToCurrentTier = this.currentTier.entryRequirement.spend - this.totalSpend;
                    return this.dateTierExpires
                        ? this.$t(
                            `Spend {currencySymbol}{value} more by {date} to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.`,
                            {
                                currencySymbol: this.currencySymbol,
                                value: spendToCurrentTier,
                                date: formatDate(this.dateTierExpires),
                                currentTierName: this.currentTier.name,
                                previousTierName: this.previousTier.name
                            }
                        )
                        : this.$t(
                            `Spend {currencySymbol}{value} more to remain in {currentTierName} tier, otherwise you'll be moved down to {previousTierName}.`,
                            {
                                currencySymbol: this.currencySymbol,
                                value: spendToCurrentTier,
                                currentTierName: this.currentTier.name,
                                previousTierName: this.previousTier.name
                            }
                        );
                case 'on-highest-tier':
                    return this.$t(`Congratulations, you've reached the highest tier`);
            }
        }
    }
}

type TierPromptType = 'upgrade' | 'maintain' | 'on-highest-tier';
