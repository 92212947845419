
import { Options, Vue } from 'vue-class-component';
import { Asset } from '@okendo/reviews-common';

import LoyaltyCustomIcon from './LoyaltyCustomIcon.vue';


@Options({
    components: {
        LoyaltyCustomIcon
    },
    props: {
        title: String,
        subtitle: String,
        icon: [String, Object],
        layout: String,
        disabled: Boolean
    }
})
export default class LoyaltyCard extends Vue {
    title!: string;
    subtitle?: string;
    icon!: Asset.CustomIcon;
    layout!: 'inline' | 'stacked';
    disabled?: boolean;
}
