import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { LoyaltyAuthModuleMethod } from '@/store/modules/loyalty-auth';
import { LoyaltyModuleMethod } from '@/store/modules/loyalty';
import { postLogin } from '@/utils/loyaltyApi';

export async function attemptUserAuth(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const loyaltyCustomerId = urlParams.get('loyaltyCustomerId');

    if (store.state.order.reviewRequestId) {
        if (loyaltyCustomerId) {
            store.commit<StoreMethod>({ type: 'loyalty/SET_LOYALTY_CUSTOMER_ID', loyaltyCustomerId });
        }

        if (store.state.loyalty.loyaltyCustomerId) {
            const res = await postLogin(store.state.order.reviewRequestId, store.state.loyalty.loyaltyCustomerId);
            store.commit<LoyaltyAuthModuleMethod>({ type: 'loyaltyAuth/SET_TOKEN', token: res.jwt });
        }
    }
    else {
        // Clean up user data if authentication is not possible.
        store.commit<LoyaltyAuthModuleMethod>({ type: 'loyaltyAuth/CLEAR_TOKEN' });
        store.commit<LoyaltyModuleMethod>({ type: 'loyalty/CLEAR_MEMBER' });
        store.commit<StoreMethod>({ type: 'loyalty/CLEAR_MEMBER_RULES' });
        store.commit<StoreMethod>({ type: 'loyalty/CLEAR_LOYALTY_CUSTOMER_ID' });
    }
}
