import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "width", "height"]
const _hoisted_2 = {
  key: 0,
  class: "c-vipTierBadge-check"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.badgeImageUrl)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{
            'is-active': _ctx.isActive,
            'c-vipTierBadge--small': _ctx.isSmall
        }, "c-vipTierBadge"])
      }, [
        _createElementVNode("img", {
          src: _ctx.badgeImageUrl,
          width: _ctx.isSmall ? 24 : 32,
          height: _ctx.isSmall ? 24 : 32,
          draggable: "false",
          class: "c-vipTierBadge-image"
        }, null, 8, _hoisted_1),
        (_ctx.isActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: "check",
                size: "10px",
                label: "Tier achieved"
              })
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}