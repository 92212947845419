
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        title: String
    }
})
export default class LoyaltyCard extends Vue {
    title!: string;

    get hasSubtitleSlot() {
        return !!this.$slots.subtitle;
    }
}
