
import { Asset } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';

@Options({
    props: {
        icon: [String, Object],
        label: {
            type: String,
            required: false
        },
        size: {
            type: String,
            required: false,
            default: '16px'
        }
    },
    components: {
        FontAwesomeIcon
    }
})
export default class CustomIcon extends Vue {
    icon!: Asset.CustomIcon;
    size!: string;
    label!: string;

    get iconImageUrl(): string | undefined {
        return typeof this.icon === 'string' ? undefined : this.icon.url;
    }

    get customImageSize(): number {
        // custom images often look smaller, so we're boosting their size slightly to compensate
        return parseInt(this.size) + 4;
    }
}
