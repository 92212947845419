
import { Options, Vue } from 'vue-class-component';
import { LoyaltyCustomerViewPublic, LoyaltyEarningRuleViewPublicAuth, LoyaltyEarningRuleViewPublic, ProductLike } from '@okendo/reviews-common';

import LoyaltyEarnCard from './LoyaltyEarnCard.vue';
import LoyaltyModule from './LoyaltyModule.vue';
import store from '@/store';

@Options({
    components: {
        LoyaltyEarnCard,
        LoyaltyModule
    },
    store
})
export default class LoyaltyEarn extends Vue {
    get member(): LoyaltyCustomerViewPublic | null {
        return store.state.loyalty.member;
    }

    get showEarnModule(): boolean {
        return !!(this.isLoadingRules || this.earningRules.length) && this.member?.status === 'enrolled';
    }

    get isLoadingRules(): boolean {
        return store.state.loyalty.isLoadingMemberLoyaltyRules || store.state.loyalty.isLoadingLoyaltyRules;
    }

    get earningRules(): LoyaltyEarningRuleViewPublicAuth[] {
        return this.isPreviewMode ? this.mapExampleRules(store.state.loyalty.earningRules) : this.filterRules(store.state.loyalty.memberEarningRules);
    }

    filterRules(rules: LoyaltyEarningRuleViewPublicAuth[] | undefined): LoyaltyEarningRuleViewPublicAuth[] {
        return rules?.filter(rule => {
            if (rule.action === 'submit-review') {
                return !rule.isCompleted && this.remainingProductsToReview.length > 0;
            }
            return !rule.isCompleted && rule.action !== 'sign-up';
        }) || [];
    }

    mapExampleRules(rules: LoyaltyEarningRuleViewPublic[] | undefined): LoyaltyEarningRuleViewPublicAuth[] {
        const filteredRules = rules?.filter(rule => rule.action !== 'sign-up') || [];
        return filteredRules.map(rule => ({ ...rule, isCompleted: false }));
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get remainingProductsToReview(): ProductLike[] {
        return store.state.order.remainingProductsToReview || [];
    }
}
