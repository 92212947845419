import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-loyaltyModule c-module--gap-small" }
const _hoisted_2 = { class: "c-loyaltyModule-header" }
const _hoisted_3 = { class: "c-loyaltyModule-header-title" }
const _hoisted_4 = {
  key: 0,
  class: "u-fontSize-vue--small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.hasSubtitleSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "subtitle")
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}