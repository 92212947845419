
import { Options, Vue } from 'vue-class-component';
import { ProductLike } from '@okendo/reviews-common';

import ProductImage from '@/shared-components/ProductImage.vue';
import StarRating from '@/shared-components/StarRating.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getProduct, postAnalytics, postErrorAnalytics } from '@/utils/api';
import { checkForLocationAttributes } from '@/utils/countriesUtil';

@Options({
    props: {
        product: {
            type: Object,
            default: {
                productId: '',
                name: ''
            }
        },
        layout: {
            type: String,
            default: 'stacked'
        }
    },
    components: {
        ProductImage,
        StarRating
    },
    store
})
export default class ProductToReview extends Vue {
    product!: ProductLike;
    productRating = '0';
    isLoading = false;
    layout?: 'inline' | 'stacked';

    async startNewProductReview(rating: string): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        const { productId } = this.product;

        try {
            const { product } = await getProduct(productId);
            store.commit<StoreMethod>({
                type: 'product/UPDATE_PRODUCT',
                product
            });

            // In case countries weren't loaded for the previous product
            await checkForLocationAttributes(product);

            store.commit<StoreMethod>({
                type: 'review/CLEAR_REVIEW'
            });

            // To remove the [x%]->[y%] from the next review in the same session
            store.commit<StoreMethod>({
                type: 'reward/UPDATE_PREVIOUS_REWARD',
                previouslyAchievedReward: undefined
            });

            store.commit<StoreMethod>({
                type: 'reviewForm/START_NEW_REVIEW',
                rating
            });

            this.$router.push({
                path: '/',
                query: {
                    ...this.$route.query,
                    productId,
                    rating
                }
            });
        }
        catch (error) {
            postErrorAnalytics(error);
            this.isLoading = false;
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: 'Failed to load product details, please try again',
                    status: 'fail'
                }
            });
        }
    }

    get rating(): string {
        return this.productRating;
    }

    set rating(rating: string) {
        this.productRating = rating;
        this.startNewProductReview(rating);

        postAnalytics({
            eventName: 'change-new-product-rating',
            label: 'rating',
            value: rating
        });
    }
}
