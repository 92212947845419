
import { Vue, Options } from 'vue-class-component';
import { LoyaltyVIPTier } from '@okendo/reviews-common';

import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';

@Options({
    components: {
        FontAwesomeIcon
    },
    props: {
        style: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        }
    }
})
export default class Badge extends Vue {
    style!: LoyaltyVIPTier.Style;
    isActive?: boolean;
    isSmall?: boolean;

    get badgeImageUrl(): string {
        return this.style.badge.type === 'uploaded' ? this.style.badge.url : this.style.badge.svgUrl;
    }
}
