
import { Options, Vue } from 'vue-class-component';
import { LoyaltyCustomerViewPublic, LoyaltyRedemptionRuleViewPublic, StyleSettings } from '@okendo/reviews-common';

import LoyaltyModule from './LoyaltyModule.vue';
import LoyaltyRedeemCard from './LoyaltyRedeemCard.vue';
import store from '@/store';
import { getRewardCurrencyWithValue } from '@/utils/rewardUtils';

@Options({
    components: {
        LoyaltyModule,
        LoyaltyRedeemCard
    },
    store
})
export default class LoyaltyRedeem extends Vue {
    get loyaltyRedeemModule(): StyleSettings.RecorderPlus.Module.LoyaltyRedeem | undefined {
        const { postReviewModules } = store.state.settings.settings;
        const loyaltyRedeemModule = postReviewModules?.find(module => module.type === 'loyalty-redeem');
        return loyaltyRedeemModule as StyleSettings.RecorderPlus.Module.LoyaltyRedeem | undefined;
    }

    get member(): LoyaltyCustomerViewPublic | null {
        return store.state.loyalty.member;
    }

    get showRedeemModule() {
        return !!(this.isLoadingRules || this.redemptionRules?.length) && this.member?.status === 'enrolled';
    }

    get formattedMemberBalance(): string {
        return getRewardCurrencyWithValue(this.memberBalance);
    }

    get showAvailableRewardsOnly(): boolean {
        return !!this.loyaltyRedeemModule?.showAvailableRewardsOnly;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get isLoadingRules(): boolean {
        return store.state.loyalty.isLoadingMemberLoyaltyRules || store.state.loyalty.isLoadingLoyaltyRules;
    }

    get memberBalance(): number {
        return store.state.loyalty.member?.points.balance ?? 0;
    }

    get redemptionRules(): LoyaltyRedemptionRuleViewPublic[] | undefined {
        return this.isPreviewMode ?
            this.filterRules(store.state.loyalty.redemptionRules) :
            this.filterRules(store.state.loyalty.memberRedemptionRules);
    }

    filterRules(rules: LoyaltyRedemptionRuleViewPublic[] | undefined): LoyaltyRedemptionRuleViewPublic[] | undefined {
        return rules?.filter(rule => {
            const isVariableCoupon = rule.type === 'variable-coupon';

            if (this.showAvailableRewardsOnly) {
                return !isVariableCoupon && rule.reward.points <= this.memberBalance;
            }

            return !isVariableCoupon;
        });
    }
}
