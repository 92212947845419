
import { Options, Vue } from 'vue-class-component';
import { CouponViewPublic, DateString, LoyaltyCustomerViewPublic } from '@okendo/reviews-common';

import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';
import LoyaltyCard from './LoyaltyCard.vue';
import LoyaltyModule from './LoyaltyModule.vue';
import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { formatDate } from '@/utils/dateUtil';
import type { ResourceState } from '@/store/modules/loyalty';

@Options({
    components: {
        FontAwesomeIcon,
        LoyaltyCard,
        LoyaltyModule
    },
    store
})
export default class LoyaltyCoupons extends Vue {
    testCoupon: CouponViewPublic = {
        code: 'OKE-XXXX',
        description: 'Test Coupon',
        expiryDate: '9999-12-12T09:00:00.000Z',
        status: 'issued'
    };

    get coupons(): CouponViewPublic[] {
        return this.isPreviewMode ? [
            this.testCoupon, this.testCoupon, this.testCoupon
        ] : store.state.loyalty.coupons;
    }

    get couponsState(): ResourceState {
        return this.isPreviewMode ? 'complete' : store.state.loyalty.couponsState;
    }

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get member(): LoyaltyCustomerViewPublic | null {
        return store.state.loyalty.member;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get showCouponsModule(): boolean {
        return this.isPreviewMode || (this.couponsState !== 'error' && this.couponsState !== 'unloaded' && this.member?.status === 'enrolled');
    }

    formatDate(date: DateString): string {
        return formatDate(date);
    }

    async copyCoupon(code: CouponViewPublic['code']): Promise<void> {
        if (this.isPreviewMode) {
            return;
        }

        try {
            await navigator.clipboard.writeText(code);

            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Coupon copied to clipboard'),
                    status: 'success'
                }
            });

            window.open(`${this.storeUrl}/discount/${code}`, '_blank');
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Coupon could not be copied. Please copy manually.'),
                    status: 'fail'
                }
            });
        }
    }
}
