
import { LoyaltyCustomerViewPublic } from '@okendo/reviews-common';
import { Options, Vue } from 'vue-class-component';

import { getRewardCurrencyPlural } from '@/utils/rewardUtils';
import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';
import { BaseModalResponse } from '@/shared-components/Modal.vue';

@Options({
    components: {
        FontAwesomeIcon
    },
    props: {
        existingBirthday: Object
    },
    emits: {
        onSave: Object
    }
})
export default class BirthdayForm extends Vue {
    existingBirthday?: LoyaltyCustomerViewPublic['birthday'];
    day: number | null = null;
    month: number | null = null;
    monthOptions: string[] = [];
    isFormTouched = false;

    override mounted(): void {
        this.monthOptions = [
            this.$t('January'),
            this.$t('February'),
            this.$t('March'),
            this.$t('April'),
            this.$t('May'),
            this.$t('June'),
            this.$t('July'),
            this.$t('August'),
            this.$t('September'),
            this.$t('October'),
            this.$t('November'),
            this.$t('December')
        ];

        this.day = this.existingBirthday?.day ?? null;
        this.month = this.existingBirthday?.month ?? null;
    }

    get birthdayWarningText(): string {
        const rewardCurrencyPlural = getRewardCurrencyPlural();
        return this.$t(`You can only receive {rewardCurrencyPlural} for your birthday once every 12 months. If you've already been awarded {rewardCurrencyPlural} this year, you won't receive more {rewardCurrencyPlural} for your birthday until the following year.`, { rewardCurrencyPlural });
    }

    get isFormValid(): boolean {
        return this.isDayValid && this.isMonthValid;
    }

    get isDayModified(): boolean {
        return this.day !== (this.existingBirthday?.day ?? null);
    }

    get isMonthModified(): boolean {
        return this.month !== (this.existingBirthday?.month ?? null);
    }

    get isDayValid(): boolean {
        if (typeof this.day === 'string' || !this.day) {
            return false;
        }

        // Get the number of days in the selected month
        const defaultMonth = 1; // Default to January so it doesn't show an error if they haven't selected a month yet
        const defaultYear = 4; // Default to a leap year to make sure February 29 can be selected as year is unspecified
        const daysInMonth = new Date(defaultYear, this.month ?? defaultMonth, 0).getDate();

        // Check if day is between 1 and the number of days in the selected month
        return this.day >= 1 && this.day <= daysInMonth;
    }

    get isMonthValid(): boolean {
        return !!this.month && this.month >= 1 && this.month <= 12;
    }

    get showWarning(): boolean {
        return !!this.existingBirthday && this.isFormTouched;
    }

    get isButtonDisabled(): boolean {
        return !this.isFormValid || !this.isFormTouched;
    }

    onFormChange(): void {
        this.isFormTouched = true;
    }

    onSave(): void {
        if (!this.day || !this.month) {
            return;
        }

        const modalResponse: BirthdayModalResponse = {
            confirmation: true,
            birthday: {
                day: this.day,
                month: this.month
            }
        };

        this.$emit('onSave', modalResponse);
    }
}

export interface BirthdayModalResponse extends BaseModalResponse {
    birthday: LoyaltyCustomerViewPublic['birthday'];
}
