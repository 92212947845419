
import { Options, Vue } from 'vue-class-component';
import { LoyaltyEarningRuleViewPublicAuth, LoyaltyCustomerViewPublic, ProductLike } from '@okendo/reviews-common';

import BirthdayForm, { BirthdayModalResponse } from './BirthdayForm.vue';
import Bubble from '@/shared-components/Bubble.vue';
import LoyaltyCard from './LoyaltyCard.vue';
import Modal from '@/shared-components/Modal.vue';
import ProductToReview from '@/shared-components/ProductToReview.vue';
import FontAwesomeIcon from '@/shared-components/FontAwesomeIcon.vue';
import Icon from '@/shared-components/Icon.vue';
import store from '@/store';
import { getRewardCurrencyWithValue } from '@/utils/rewardUtils';
import { StoreMethod } from '@/store/storeTypings';
import { postTriggerEarningRule, putCustomerBirthday } from '@/utils/loyaltyAuthenticatedApi';
import { usePromisedModal } from '@/utils/usePromisedModal';

@Options({
    components: {
        BirthdayForm,
        Bubble,
        LoyaltyCard,
        Modal,
        ProductToReview,
        FontAwesomeIcon,
        Icon
    },
    props: {
        rule: Object
    },
    store
})
export default class LoyaltyEarnCard extends Vue {
    loading = false;
    rule!: LoyaltyEarningRuleViewPublicAuth;
    isProductsPanelVisible = false;
    birthdayModal = usePromisedModal<BirthdayModalResponse>();
    isBirthdayUpdated = false;

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get remainingProductsToReview(): ProductLike[] {
        return store.state.order.remainingProductsToReview || [];
    }

    get memberPoints(): LoyaltyCustomerViewPublic.Points | undefined {
        return store.state.loyalty.member?.points;
    }

    get completed(): boolean {
        return 'isCompleted' in this.rule && this.rule.isCompleted;
    }

    get isDisabled(): boolean {
        return (this.completed || this.loading);
    }

    get currencySymbol(): string {
        return store.state.subscriber.currencySymbol;
    }

    get buttonText(): string {
        if (this.rule.action === 'birthday') {
            return store.state.loyalty.member?.birthday ? this.$t('Edit Date') : this.$t('Set Date');
        }

        return this.$t('Go');
    }

    get existingBirthday(): LoyaltyCustomerViewPublic['birthday'] {
        return store.state.loyalty.member?.birthday;
    }

    async saveBirthday(birthdayResponse: BirthdayModalResponse | undefined): Promise<void> {
        const { confirmation, birthday } = birthdayResponse || {};
        if (!confirmation || !birthday) {
            return;
        }

        try {
            await putCustomerBirthday(birthday);
            this.isBirthdayUpdated = true;

            const member = store.state.loyalty.member;
            if (member) {
                store.commit<StoreMethod>({
                    type: 'loyalty/SET_MEMBER',
                    member: {
                        ...member,
                        birthday
                    }
                });
            }
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: this.$t('Failed to update birthday, please try again'),
                    status: 'fail'
                }
            });
        }
    }

    toggleProductsPanel(): void {
        this.isProductsPanelVisible = !this.isProductsPanelVisible;
    }

    getRuleDescription(rule: LoyaltyEarningRuleViewPublicAuth): string {
        if (rule.reward.type === 'submit-review') {
            const { amountForImage, amountForReview, amountForSocialLogin, amountForVideo, capPerOrder } = rule.reward;
            return `Up to ${getRewardCurrencyWithValue(
                Math.min(
                    amountForImage + amountForReview + amountForSocialLogin + amountForVideo,
                    capPerOrder || Infinity
                )
            )}`;
        }

        return `${getRewardCurrencyWithValue(rule.reward.amount)}${rule.action === 'purchase' ? ` per ${this.currencySymbol}${1} spent` : ''}`;
    }

    async onClick(rule: LoyaltyEarningRuleViewPublicAuth): Promise<void> {
        if (this.isPreviewMode) {
            return;
        }

        switch (rule.action) {
            case 'purchase':
                location.href = this.storeUrl;
                break;

            case 'birthday':
                this.birthdayModal.openModal().then(this.saveBirthday);
                break;

            case 'facebook-share':
            case 'instagram-follow':
            case 'twitter-follow':
            case 'twitter-post':
            case 'facebook-like':
            case 'tiktok-follow':
                this.loading = true;

                try {
                    const { pointsEarned } = await postTriggerEarningRule(rule.earningRuleId);
                    window.open(rule.socialPageLink, '_blank');

                    // Update point balance
                    if (this.memberPoints) {
                        store.commit<StoreMethod>({
                            type: 'loyalty/SET_POINTS',
                            points: {
                                ...this.memberPoints,
                                balance: this.memberPoints.balance + pointsEarned
                            }
                        });
                    }

                    if ('isCompleted' in rule) {
                        rule.isCompleted = true;
                    }
                }
                catch {
                    store.dispatch<StoreMethod>({
                        type: 'alert/SHOW',
                        alertData: {
                            content: this.$t('Error, this reward may have already been redeemed'),
                            status: 'fail'
                        }
                    });
                }
                finally {
                    this.loading = false;
                }

                break;
        }
    }
}
