
import { library } from '@fortawesome/fontawesome-svg-core';
import { Options, Vue } from 'vue-class-component';
import type { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import { Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faFacebookMessenger, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import store from '@/store';

@Options({
    props: {
        icon: String,
        label: {
            type: String,
            required: false
        },
        size: {
            type: String,
            required: false,
            default: '16px'
        }
    },
    components: {
        FontAwesomeIcon
    },
    store
})
export default class Icon extends Vue {
    // To view/search more icons: https://fontawesome.com/search?o=r&s=light&f=classic
    icon!: IconName; // kebab case, does not include the 'fa-' prefix
    size!: `${string}px`;
    label!: string;
    fetchedIcon: IconDefinition | null = null;

    @Watch('icon')
    onIconChange(iconNew: IconName, iconOld: IconName): void {
        if (iconNew !== iconOld) {
            this.fetchedIcon = null;
            this.handleIconChange();
        }
    }

    override created(): void {
        this.handleIconChange();
    }

    handleIconChange(): void {
        switch (this.icon) {
            case 'facebook':
                this.fetchedIcon = faFacebook;
                library.add(faFacebook);
                return;
            case 'facebook-messenger':
                this.fetchedIcon = faFacebookMessenger;
                library.add(faFacebookMessenger);
                return;
            case 'twitter':
                this.fetchedIcon = faTwitter;
                library.add(faTwitter);
                return;
            case 'whatsapp':
                this.fetchedIcon = faWhatsapp;
                library.add(faWhatsapp);
                return;
            case 'check':
                if (parseInt(this.size, 0) < 16) {
                    this.fetchedIcon = faCheck;
                    library.add(faCheck);
                    return;
                }
                break;
            case 'xmark':
                if (parseInt(this.size, 0) < 16) {
                    this.fetchedIcon = faXmark;
                    library.add(faXmark);
                    return;
                }
                break;
        }

        this.fetchIcon();
    }

    get iconModuleName(): string {
        // kebab-case to PascalCase + 'fa' prefix
        return `fa${this.icon.replace(/(^\w|-\w)/g, (icon) => icon.replace(/-/, '').toUpperCase())}`;
    }

    async fetchIcon(): Promise<void> {
        if (!this.fetchedIcon) {
            const { definition } = await import(
                /* webpackChunkName: "icons/[request]" */
                `../../node_modules/@okendo/fontawesome-light-icons/${this.iconModuleName}.js`
            );
            if (definition) {
                this.fetchedIcon = definition;
                library.add(definition);
            }
        }
    }
}
