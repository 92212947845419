import { LoyaltyVIPProgramViewPublic } from '@okendo/reviews-common';

import store from '@/store';

export function getCurrentTierIndex(): number {
    if (store.state.subscriber.previewMode) {
        return 0;
    }

    const vipTiers = store.state.loyalty.vipTiers;
    const member = store.state.loyalty.member;
    return member && vipTiers ? vipTiers.findIndex(({ vipTierId }) => vipTierId === member.vipTierId) ?? 0 : 0;
}

export function getCurrentTier(): LoyaltyVIPProgramViewPublic.VIPTier | undefined {
    const { vipTiers } = store.state.loyalty;
    return vipTiers?.[getCurrentTierIndex()];
}
