import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'c-customIcon--customImage': !!_ctx.iconImageUrl }, "c-customIcon"])
  }, [
    (_ctx.iconImageUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.iconImageUrl,
          width: _ctx.customImageSize,
          height: _ctx.customImageSize,
          class: "c-customIcon-image"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: _ctx.icon,
          size: _ctx.size,
          label: _ctx.label
        }, null, 8, ["icon", "size", "label"]))
  ], 2))
}