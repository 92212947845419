import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7257ddb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "c-loyaltyProfile"
}
const _hoisted_2 = {
  key: 0,
  class: "c-loyaltyProfile-member"
}
const _hoisted_3 = { class: "c-loyaltyProfile-member-header" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "c-loyaltyProfile-member-header-details" }
const _hoisted_6 = {
  key: 0,
  class: "c-loyaltyProfile-member-header-details-name"
}
const _hoisted_7 = {
  key: 1,
  class: "c-loyaltyProfile-member-header-details-email"
}
const _hoisted_8 = { class: "c-loyaltyProfile-member-points" }
const _hoisted_9 = { class: "c-loyaltyProfile-member-points-label" }
const _hoisted_10 = { class: "c-loyaltyProfile-member-points-value" }
const _hoisted_11 = {
  key: 1,
  class: "c-loyaltyProfile-join"
}
const _hoisted_12 = { class: "c-loyaltyProfile-join-header" }
const _hoisted_13 = { class: "c-loyaltyProfile-join-header-title" }
const _hoisted_14 = { class: "c-loyaltyProfile-join-header-title-icon" }
const _hoisted_15 = { class: "c-loyaltyProfile-join-header-subtitle" }
const _hoisted_16 = {
  key: 0,
  class: "c-loader c-loader--small c-loader--buttonFontColor"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (_ctx.member?.status === 'enrolled' || _ctx.showJoinForm)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.member?.status === 'enrolled')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.avatarUrl,
                  alt: "",
                  class: "c-loyaltyProfile-member-header-avatar"
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.member.fullName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.member.fullName), 1))
                    : _createCommentVNode("", true),
                  (_ctx.member.email)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.member.email), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('Your Balance')), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$getRewardCurrencyWithValue(_ctx.member.points.balance)), 1)
              ])
            ]))
          : (_ctx.showJoinForm)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("h3", _hoisted_13, [
                    (_ctx.hasSubmittedJoinProgramForm)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Verification Email Sent')) + " ", 1),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_icon, {
                              icon: "success",
                              class: "c-icon--success c-icon--small"
                            })
                          ])
                        ], 64))
                      : (_ctx.pointsOnSignUp > 0)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('You have {points} waiting', {
                                points: _ctx.$getRewardCurrencyWithValue(_ctx.pointsOnSignUp)
                            })), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('Your perks are waiting')), 1)
                          ], 64))
                  ]),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.hasSubmittedJoinProgramForm ?
                            _ctx.$t(`We've sent an email to the email address you provided, please check your emails and follow the sign up link`) :
                            _ctx.$t('Join {programName} now to redeem rewards and unlock exclusive perks!', {
                                programName: _ctx.programName
                            })), 1)
                ]),
                (!_ctx.hasSubmittedJoinProgramForm)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.joinProgram && _ctx.joinProgram(...args))),
                      class: "c-button c-loyaltyModule-button"
                    }, [
                      (_ctx.isJoiningProgram)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                        : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('Join Program')), 1))
                    ]))
                  : _withDirectives((_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gotoStore())),
                      "aria-label": _ctx.$t('Return to {storeName}', { storeName: _ctx.storeName }),
                      class: "c-button c-loyaltyModule-button"
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('Return to {storeName}', { storeName: _ctx.storeName })), 1)
                    ], 8, _hoisted_18)), [
                      [_directive_prevent_click_in_preview]
                    ])
              ]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}