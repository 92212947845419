import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "u-assistive-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon", true)!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.size !== '16px' ? { width: _ctx.size, height: _ctx.size } : undefined),
    class: "c-icon"
  }, [
    (_ctx.fetchedIcon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: _ctx.fetchedIcon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 4))
}