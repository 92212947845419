import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { Coupon, CouponViewPublic, LoyaltyCustomerViewPublic, LoyaltySettings, LoyaltyVIPProgramViewPublic, LoyaltyEarningRuleViewPublic, LoyaltyEarningRuleViewPublicAuth, LoyaltyRedemptionRuleViewPublic } from '@okendo/reviews-common';
import type { AxiosResponse } from 'axios';

import store from '@/store';
import { StoreMethod } from '../storeTypings';
import { exampleLoyaltyMember, isAuthenticated } from '@/utils/loyaltyUtils';
import { getLoyaltySettings, getVipTiers, getRedemptionRules as publicGetRedemptionRules, getEarningRules as publicGetEarningRules } from '@/utils/loyaltyApi';
import { getLoyaltyCustomerDetails, getLoyaltyCustomerCoupons, getEarningRules, getRedemptionRules, getLoyaltyCustomerSpend } from '@/utils/loyaltyAuthenticatedApi';
import { attemptUserAuth } from '@/utils/loyaltyInitialisationUtil';
import type { LoyaltyCustomerSpend } from '@/utils/loyaltyAuthenticatedApi';

@Module({ namespaced: true })
export class LoyaltyModule extends VuexModule {
    coupons: CouponViewPublic[] = [];
    earningRules?: LoyaltyEarningRuleViewPublic[];
    generalSettings?: LoyaltySettings.General;
    isInitialisingLoyalty = false;
    isLoadingCustomerData = false;
    isLoadingLoyaltyRules = false;
    isLoadingMemberLoyaltyRules = false;
    isLoadingVipTiers = false;
    isUpdatingCustomerData = false;
    isVipTiersEnabled = false;
    member: LoyaltyCustomerViewPublic | null = null;
    memberSpend: LoyaltyCustomerSpend | null = null;
    memberEarningRules?: LoyaltyEarningRuleViewPublicAuth[];
    memberRedemptionRules?: LoyaltyRedemptionRuleViewPublic[];
    redemptionRules?: LoyaltyRedemptionRuleViewPublic[];
    vipTiers: LoyaltyVIPProgramViewPublic.VIPTier[] = [];
    couponsState: ResourceState = 'unloaded';
    loyaltyCustomerId: string | null = null;
    customerSpendState: CustomerDataResourceState = 'unloaded';
    vipTiersState: ResourceState = 'unloaded';

    @Mutation
    SET_LOYALTY_CUSTOMER_ID({ loyaltyCustomerId }: SetLoyaltyCustomerId): void {
        this.loyaltyCustomerId = loyaltyCustomerId;
    }

    @Mutation
    CLEAR_LOYALTY_CUSTOMER_ID(): void {
        this.loyaltyCustomerId = null;
    }

    @Mutation
    SET_MEMBER({ member }: SetMember): void {
        this.member = member;
    }

    @Mutation
    CLEAR_MEMBER(): void {
        this.member = null;
    }

    @Mutation
    SET_IS_INITIALISING_LOYALTY({ isInitialisingLoyalty }: SetisInitialisingLoyalty): void {
        this.isInitialisingLoyalty = isInitialisingLoyalty;
    }

    @Mutation
    SET_IS_LOADING_CUSTOMER_DATA({ isLoadingCustomerData }: SetIsLoadingCustomerData): void {
        this.isLoadingCustomerData = isLoadingCustomerData;
    }

    @Mutation
    SET_IS_UPDATING_CUSTOMER_DATA({ isUpdatingCustomerData }: SetIsUpdatingCustomerData): void {
        this.isUpdatingCustomerData = isUpdatingCustomerData;
    }

    @Mutation
    SET_IS_LOADING_VIP_TIERS({ isLoadingVipTiers }: SetIsLoadingVipTiers): void {
        this.isLoadingVipTiers = isLoadingVipTiers;
    }

    @Action({ rawError: true })
    async INITIALISE_LOYALTY(): Promise<void> {
        store.commit<StoreMethod>({
            type: 'loyalty/SET_IS_INITIALISING_LOYALTY',
            isInitialisingLoyalty: true
        });

        try {
            const { settings } = await getLoyaltySettings();

            store.commit<StoreMethod>({
                type: 'loyalty/SET_GENERAL_SETTINGS',
                general: settings.general
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_VIP_TIERS_ENABLED',
                isVipTiersEnabled: settings.isVipTiersEnabled
            });

            await store.dispatch<StoreMethod>({
                type: 'loyalty/GET_LOYALTY_RULES'
            });
        }
        catch {
            throw new Error('Failed to load, please try again');
        }

        store.commit<StoreMethod>({
            type: 'loyalty/SET_IS_INITIALISING_LOYALTY',
            isInitialisingLoyalty: false
        });
    }

    @Mutation
    SET_GENERAL_SETTINGS({ general }: SetGeneralSettings): void {
        this.generalSettings = general;
    }

    @Mutation
    SET_VIP_TIERS_ENABLED({ isVipTiersEnabled }: SetVIPTiersEnabled): void {
        this.isVipTiersEnabled = isVipTiersEnabled;
    }

    @Mutation
    SET_MEMBER_EARNING_RULES({ earningRules }: SetAuthEarningRules): void {
        this.memberEarningRules = earningRules ?? [];
    }

    @Mutation
    SET_MEMBER_REDEMPTION_RULES({ redemptionRules }: SetAuthRedemptionRules): void {
        this.memberRedemptionRules = redemptionRules ?? [];
    }

    @Mutation
    SET_IS_LOADING_LOYALTY_RULES({ isLoadingLoyaltyRules }: SetIsLoadingLoyaltyRules): void {
        this.isLoadingLoyaltyRules = isLoadingLoyaltyRules;
    }

    @Mutation
    SET_IS_LOADING_MEMBER_LOYALTY_RULES({ isLoadingMemberLoyaltyRules }: SetIsLoadingMemberLoyaltyRules): void {
        this.isLoadingMemberLoyaltyRules = isLoadingMemberLoyaltyRules;
    }

    @Mutation
    CLEAR_MEMBER_RULES(): void {
        this.memberEarningRules = undefined;
        this.memberRedemptionRules = undefined;
    }

    @Mutation
    SET_MEMBER_SPEND({ spend }: SetMemberSpend): void {
        this.memberSpend = spend;
    }

    @Action({ rawError: true })
    async GET_MEMBER_DATA(): Promise<void> {
        const areCustomerDetailsAlreadyFetched = isAuthenticated() && store.state.loyalty.member !== null;
        if (areCustomerDetailsAlreadyFetched) {
            return;
        }

        try {
            await store.dispatch<StoreMethod>({ type: 'loyalty/AUTH_AND_SET_MEMBER_DATA' });
        }
        catch (error) {
            if ((error as AxiosResponse).status === 401) {
                store.commit<StoreMethod>({ type: 'loyaltyAuth/CLEAR_TOKEN' });

                if (store.state.loyalty.member) {
                    // Expired token – attempt reauth
                    store.commit<StoreMethod>({ type: 'loyalty/CLEAR_MEMBER' });
                    store.commit<StoreMethod>({ type: 'loyalty/CLEAR_COUPONS' });
                    store.commit<StoreMethod>({ type: 'loyalty/CLEAR_MEMBER_RULES' });
                    await store.dispatch<StoreMethod>({
                        type: 'loyalty/AUTH_AND_SET_MEMBER_DATA'
                    });
                }
                else {
                    throw error;
                }
            }
            else {
                throw error;
            }
        }
        finally {
            store.commit<StoreMethod>({
                type: 'loyalty/SET_IS_LOADING_CUSTOMER_DATA',
                isLoadingCustomerData: false
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_IS_UPDATING_CUSTOMER_DATA',
                isUpdatingCustomerData: false
            });
        }
    }

    @Action({ rawError: true })
    async AUTH_AND_SET_MEMBER_DATA(): Promise<void> {
        store.commit<StoreMethod>({
            type: 'loyalty/SET_IS_LOADING_CUSTOMER_DATA',
            isLoadingCustomerData: true
        });

        if (store.state.subscriber.previewMode) {
            store.commit<StoreMethod>({
                type: 'loyalty/SET_MEMBER',
                member: exampleLoyaltyMember
            });
        }
        else {
            await attemptUserAuth();
            if (isAuthenticated()) {
                const [{ loyaltyCustomer }] = await Promise.all([
                    getLoyaltyCustomerDetails(),
                    store.dispatch<StoreMethod>({ type: 'loyalty/GET_MEMBER_LOYALTY_RULES' })
                ]);

                store.commit<StoreMethod>({
                    type: 'loyalty/SET_MEMBER',
                    member: loyaltyCustomer
                });

                if (loyaltyCustomer.status === 'enrolled') {
                    store.dispatch<StoreMethod>({
                        type: 'loyalty/GET_COUPONS'
                    });

                    store.dispatch<StoreMethod>({
                        type: 'loyalty/GET_MEMBER_SPEND'
                    });

                    store.dispatch<StoreMethod>({
                        type: 'loyalty/GET_VIP_TIERS'
                    });
                }
            }
        }
    }

    @Action({ rawError: true })
    async GET_COUPONS(): Promise<void> {
        store.commit<StoreMethod>({
            type: 'loyalty/SET_COUPONS_STATE',
            couponsState: 'loading'
        });

        try {
            const { coupons } = await getLoyaltyCustomerCoupons();
            store.commit<StoreMethod>({
                type: 'loyalty/SET_COUPONS',
                coupons
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_COUPONS_STATE',
                couponsState: 'complete'
            });
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: 'There was an error loading your coupons.',
                    status: 'success'
                }
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_COUPONS_STATE',
                couponsState: 'error'
            });
        }
    }

    @Mutation
    SET_COUPONS_STATE({ couponsState }: SetCouponsState): void {
        this.couponsState = couponsState;
    }

    @Mutation
    SET_COUPONS({ coupons }: SetCoupons): void {
        if (!this.member) {
            return;
        }

        const couponPriority: Coupon.Status[] = ['issued', 'used', 'expired', 'cancelled'];
        const sortedCoupons = coupons.sort(
            (a, b) =>
                couponPriority.indexOf(a.status) - couponPriority.indexOf(b.status) ||
                new Date(a.expiryDate ?? '99999').getTime() - new Date(b.expiryDate ?? '99999').getTime()
        );
        this.coupons = sortedCoupons;
    }

    @Mutation
    SET_EARNING_RULES({ earningRules }: SetEarningRules): void {
        this.earningRules = earningRules ?? [];
    }

    @Mutation
    SET_REDEMPTION_RULES({ redemptionRules }: SetRedemptionRules): void {
        this.redemptionRules = redemptionRules ?? [];
    }

    @Mutation
    CLEAR_COUPONS(): void {
        this.coupons = [];
    }

    @Action({ rawError: true })
    async GET_LOYALTY_RULES(): Promise<void> {
        try {
            const [{ earningRules }, { redemptionRules }] = await Promise.all([
                publicGetEarningRules(),
                publicGetRedemptionRules()
            ]);

            if (!redemptionRules || !earningRules) {
                throw new Error('Failed to load data');
            }

            store.commit<StoreMethod>({
                type: 'loyalty/SET_EARNING_RULES',
                earningRules
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_REDEMPTION_RULES',
                redemptionRules
            });
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: 'Unable to Load Loyalty Rules',
                    status: 'success'
                }
            });
        }
    }

    @Action({ rawError: true })
    async GET_MEMBER_LOYALTY_RULES(): Promise<void> {
        const areRulesAlreadyFetched =
            store.state.loyalty.memberEarningRules !== undefined &&
            store.state.loyalty.memberRedemptionRules !== undefined;

        if (areRulesAlreadyFetched) {
            return;
        }

        store.commit<StoreMethod>({
            type: 'loyalty/SET_IS_LOADING_MEMBER_LOYALTY_RULES',
            isLoadingMemberLoyaltyRules: true
        });

        if (isAuthenticated()) {
            try {
                const [{ earningRules }, { redemptionRules }] = await Promise.all([
                    getEarningRules(),
                    getRedemptionRules()
                ]);

                store.commit<StoreMethod>({
                    type: 'loyalty/SET_MEMBER_EARNING_RULES',
                    earningRules
                });

                store.commit<StoreMethod>({
                    type: 'loyalty/SET_MEMBER_REDEMPTION_RULES',
                    redemptionRules
                });
            }
            finally {
                store.commit<StoreMethod>({
                    type: 'loyalty/SET_IS_LOADING_MEMBER_LOYALTY_RULES',
                    isLoadingMemberLoyaltyRules: false
                });
            }
        }
    }

    @Mutation
    SET_CUSTOMER_SPEND_STATE({ customerSpendState }: SetCustomerSpendState): void {
        this.customerSpendState = customerSpendState;
    }

    @Action({ rawError: true })
    async GET_MEMBER_SPEND(): Promise<void> {
        const isVipTiersEnabled = store.state.loyalty.isVipTiersEnabled;
        if (!isVipTiersEnabled || this.customerSpendState === 'complete') {
            return;
        }

        const setState = (customerSpendState: CustomerDataResourceState): void => {
            store.commit<StoreMethod>({
                type: 'loyalty/SET_CUSTOMER_SPEND_STATE',
                customerSpendState
            });
        };

        setState('loading');

        try {
            const spend = await getLoyaltyCustomerSpend();
            store.commit<StoreMethod>({
                type: 'loyalty/SET_MEMBER_SPEND',
                spend
            });
            setState('complete');
        }
        catch {
            setState('unloaded');
        }
    }

    @Action({ rawError: true })
    async GET_VIP_TIERS(): Promise<void> {
        const isVipTiersEnabled = store.state.loyalty.isVipTiersEnabled;
        if (!store.state.subscriber.previewMode && !isVipTiersEnabled || this.vipTiersState === 'complete') {
            return;
        }

        store.commit<StoreMethod>({
            type: 'loyalty/SET_VIP_TIERS_STATE',
            vipTiersState: 'loading'
        });

        try {
            const { vipProgram: { vipTiers } } = await getVipTiers();
            store.commit<StoreMethod>({
                type: 'loyalty/SET_VIP_TIERS',
                vipTiers
            });

            store.commit<StoreMethod>({
                type: 'loyalty/SET_VIP_TIERS_STATE',
                vipTiersState: 'complete'
            });
        }
        catch (err) {
            if (err instanceof Response && err.status === 404) {
                store.commit<StoreMethod>({
                    type: 'loyalty/SET_VIP_TIERS_STATE',
                    vipTiersState: 'complete'
                });
                return;
            }

            store.commit<StoreMethod>({
                type: 'loyalty/SET_VIP_TIERS_STATE',
                vipTiersState: 'error'
            });
        }
    }

    @Mutation
    SET_VIP_TIERS_STATE({ vipTiersState }: SetVipTiersState): void {
        this.vipTiersState = vipTiersState;
    }

    @Mutation
    SET_VIP_TIERS({ vipTiers }: SetVipTiers): void {
        this.vipTiers = vipTiers;
    }

    @Mutation
    SET_POINTS({ points }: SetPoints): void {
        if (this.member) {
            this.member.points = points;
        }
    }
}

export type LoyaltyModuleMethod =
    | AuthAndSetMemberData
    | ClearCoupons
    | ClearMember
    | ClearMemberRules
    | GetMemberData
    | GetMemberLoyaltyRules
    | InitialiseLoyalty
    | SetAuthEarningRules
    | SetAuthRedemptionRules
    | SetCoupons
    | SetEarningRules
    | SetGeneralSettings
    | SetisInitialisingLoyalty
    | SetIsLoadingCustomerData
    | SetIsLoadingLoyaltyRules
    | SetIsLoadingMemberLoyaltyRules
    | SetIsLoadingVipTiers
    | SetIsUpdatingCustomerData
    | SetMember
    | SetRedemptionRules
    | SetVIPTiersEnabled
    | GetCoupons
    | SetCouponsState
    | SetLoyaltyCustomerId
    | ClearLoyaltyCustomerId
    | SetMemberSpend
    | SetCustomerSpendState
    | SetVipTiersState
    | SetVipTiers
    | GetVipTiers
    | GetMemberSpend
    | SetPoints
    | GetLoyaltyRules;

type ModulePrefix = 'loyalty';

interface SetisInitialisingLoyalty {
    type: `${ModulePrefix}/SET_IS_INITIALISING_LOYALTY`;
    isInitialisingLoyalty: boolean;
}

interface InitialiseLoyalty {
    type: `${ModulePrefix}/INITIALISE_LOYALTY`;
}

interface SetMember {
    type: `${ModulePrefix}/SET_MEMBER`;
    member: LoyaltyCustomerViewPublic;
}

interface ClearMember {
    type: `${ModulePrefix}/CLEAR_MEMBER`;
}

interface SetGeneralSettings {
    type: `${ModulePrefix}/SET_GENERAL_SETTINGS`;
    general: LoyaltySettings.General;
}

interface SetVIPTiersEnabled {
    type: `${ModulePrefix}/SET_VIP_TIERS_ENABLED`;
    isVipTiersEnabled: boolean;
}

interface AuthAndSetMemberData {
    type: `${ModulePrefix}/AUTH_AND_SET_MEMBER_DATA`;
}

interface SetIsLoadingCustomerData {
    type: `${ModulePrefix}/SET_IS_LOADING_CUSTOMER_DATA`;
    isLoadingCustomerData: boolean;
}

interface SetIsUpdatingCustomerData {
    type: `${ModulePrefix}/SET_IS_UPDATING_CUSTOMER_DATA`;
    isUpdatingCustomerData: boolean;
}

interface SetIsLoadingMemberLoyaltyRules {
    type: `${ModulePrefix}/SET_IS_LOADING_MEMBER_LOYALTY_RULES`;
    isLoadingMemberLoyaltyRules: boolean;
}

interface SetIsLoadingVipTiers {
    type: `${ModulePrefix}/SET_IS_LOADING_VIP_TIERS`;
    isLoadingVipTiers: boolean;
}

interface SetAuthEarningRules {
    type: `${ModulePrefix}/SET_MEMBER_EARNING_RULES`;
    earningRules: LoyaltyEarningRuleViewPublicAuth[];
}

interface SetAuthRedemptionRules {
    type: `${ModulePrefix}/SET_MEMBER_REDEMPTION_RULES`;
    redemptionRules: LoyaltyRedemptionRuleViewPublic[];
}

interface GetMemberLoyaltyRules {
    type: `${ModulePrefix}/GET_MEMBER_LOYALTY_RULES`;
}

interface GetMemberData {
    type: `${ModulePrefix}/GET_MEMBER_DATA`;
}

interface ClearMemberRules {
    type: `${ModulePrefix}/CLEAR_MEMBER_RULES`;
}

interface SetCoupons {
    type: `${ModulePrefix}/SET_COUPONS`;
    coupons: CouponViewPublic[];
}

interface ClearCoupons {
    type: `${ModulePrefix}/CLEAR_COUPONS`;
}

interface SetIsLoadingLoyaltyRules {
    type: `${ModulePrefix}/SET_IS_LOADING_LOYALTY_RULES`;
    isLoadingLoyaltyRules: boolean;
}

interface SetEarningRules {
    type: `${ModulePrefix}/SET_EARNING_RULES`;
    earningRules: LoyaltyEarningRuleViewPublic[];
}

interface SetRedemptionRules {
    type: `${ModulePrefix}/SET_REDEMPTION_RULES`;
    redemptionRules: LoyaltyRedemptionRuleViewPublic[];
}

interface GetCoupons {
    type: `${ModulePrefix}/GET_COUPONS`;
}

interface SetCouponsState {
    type: `${ModulePrefix}/SET_COUPONS_STATE`;
    couponsState: ResourceState;
}

export type SetLoyaltyCustomerId = {
    type: `${ModulePrefix}/SET_LOYALTY_CUSTOMER_ID`;
    loyaltyCustomerId: string;
};

interface ClearLoyaltyCustomerId {
    type: `${ModulePrefix}/CLEAR_LOYALTY_CUSTOMER_ID`;
}

interface SetMemberSpend {
    type: `${ModulePrefix}/SET_MEMBER_SPEND`;
    spend: LoyaltyCustomerSpend;
}

interface SetCustomerSpendState {
    type: `${ModulePrefix}/SET_CUSTOMER_SPEND_STATE`;
    customerSpendState: CustomerDataResourceState;
}

interface SetVipTiersState {
    type: `${ModulePrefix}/SET_VIP_TIERS_STATE`;
    vipTiersState: ResourceState;
}

interface SetVipTiers {
    type: `${ModulePrefix}/SET_VIP_TIERS`;
    vipTiers: LoyaltyVIPProgramViewPublic.VIPTier[];
}

interface GetVipTiers {
    type: `${ModulePrefix}/GET_VIP_TIERS`;
}

interface GetMemberSpend {
    type: `${ModulePrefix}/GET_MEMBER_SPEND`;
}

interface SetPoints {
    type: `${ModulePrefix}/SET_POINTS`;
    points: LoyaltyCustomerViewPublic.Points;
}

interface GetLoyaltyRules {
    type: `${ModulePrefix}/GET_LOYALTY_RULES`;
}

export type ResourceState = 'unloaded' | 'loading' | 'complete' | 'error';
type CustomerDataResourceState = ResourceState | 'updating';
