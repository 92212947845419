
import { Options, Vue } from 'vue-class-component';
import { LoyaltyCustomerViewPublic, LoyaltyEarningRuleViewPublic } from '@okendo/reviews-common';

import store from '@/store';
import { StoreMethod } from '@/store/storeTypings';
import { getTranscodedImageUrl } from '@/utils/imageUtils';
import Icon from '@/shared-components/Icon.vue';
import { postActivateCustomer } from '@/utils/loyaltyAuthenticatedApi';
import { postSendLoginLink } from '@/utils/loyaltyApi';

@Options({
    components: {
        Icon
    },
    store
})
export default class LoyaltyProfile extends Vue {
    isJoiningProgram = false;
    hasSubmittedJoinProgramForm = false;

    get storeUrl(): string {
        return store.state.subscriber.storeUrl;
    }

    get storeName(): string {
        return store.state.subscriber.storeName;
    }

    get isPreviewMode(): boolean {
        return store.state.subscriber.previewMode;
    }

    get member(): LoyaltyCustomerViewPublic | null {
        return store.state.loyalty.member;
    }

    get avatarUrl(): string | undefined {
        if (!this.member?.avatarDynamicKey) {
            return require('@/assets/images/avatar-placeholder.svg');
        }

        return getTranscodedImageUrl(this.member?.avatarDynamicKey, false, 72, 72);
    }

    get earningRules(): LoyaltyEarningRuleViewPublic[] | undefined {
        return store.state.loyalty.earningRules;
    }

    get signUpBonusPoints(): number {
        const signUpReward = this.earningRules?.find((rule) => rule.action === 'sign-up')?.reward;
        return signUpReward && 'amount' in signUpReward ? signUpReward.amount : 0;
    }

    get pointsOnSignUp(): number {
        return (this.member?.points.balance ?? 0) + this.signUpBonusPoints;
    }

    get programName(): string {
        return store.state.loyalty.generalSettings?.programName ?? 'The Loyalty Program';
    }

    get showJoinForm(): boolean {
        if (this.isPreviewMode) {
            return true;
        }

        const isNewAnonymousUser = this.isAnonymousReview && !!store.state.profile.reviewerProfile.email;
        const isNewKnownUser = !!this.member;

        return isNewAnonymousUser || isNewKnownUser;
    }

    get isAnonymousReview(): boolean {
        return !store.state.order.reviewRequestId && !store.state.profile.isLoggedIn && !store.state.verification.emailVerificationToken;
    }

    async joinProgram(): Promise<void> {
        if (this.isPreviewMode) {
            return;
        }

        this.isJoiningProgram = true;

        try {
            if (this.isAnonymousReview && store.state.profile.reviewerProfile.email) {
                try {
                    await postSendLoginLink(store.state.profile.reviewerProfile.email);
                    this.hasSubmittedJoinProgramForm = true;
                }
                catch {
                    store.dispatch<StoreMethod>({
                        type: 'alert/SHOW',
                        alertData: {
                            content: 'An error occurred sending the login link email, please try again',
                            status: 'fail'
                        }
                    });
                }
            }
            else {
                if (!this.member) {
                    throw Error('No member');
                }

                const { loyaltyCustomer } = await postActivateCustomer();

                store.commit<StoreMethod>({
                    type: 'loyalty/SET_MEMBER',
                    member: {
                        ...this.member,
                        status: loyaltyCustomer.status,
                        points: loyaltyCustomer.points,
                        rewardsClaimed: loyaltyCustomer.rewardsClaimed
                    }
                });

                store.dispatch<StoreMethod>({
                    type: 'alert/SHOW',
                    alertData: {
                        content: `Welcome To ${this.programName}!`,
                        status: 'success'
                    }
                });

                store.dispatch<StoreMethod>({
                    type: 'loyalty/GET_COUPONS'
                });

                store.dispatch<StoreMethod>({
                    type: 'loyalty/GET_MEMBER_SPEND'
                });

                store.dispatch<StoreMethod>({
                    type: 'loyalty/GET_VIP_TIERS'
                });
            }
        }
        catch {
            store.dispatch<StoreMethod>({
                type: 'alert/SHOW',
                alertData: {
                    content: 'An error occurred, please try again',
                    status: 'fail'
                }
            });
        }
        finally {
            this.isJoiningProgram = false;
        }
    }

    gotoStore(): void {
        if (store.state.loyalty.generalSettings?.storeLoyaltyPagePath) {
            const loyaltyPageUrl = new URL(store.state.loyalty.generalSettings.storeLoyaltyPagePath, this.storeUrl);
            window.location.href = loyaltyPageUrl.href;
        }
        else {
            window.location.href = this.storeUrl;
        }
    }
}
