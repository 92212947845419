import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-loyaltyCard-inner" }
const _hoisted_2 = { class: "c-loyaltyCard-content" }
const _hoisted_3 = { class: "c-loyaltyCard-content-text" }
const _hoisted_4 = { class: "c-loyaltyCard-title" }
const _hoisted_5 = {
  key: 0,
  class: "c-loyaltyCard-subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loyalty_custom_icon = _resolveComponent("loyalty-custom-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
            'c-loyaltyCard--inline': _ctx.layout === 'inline',
            'is-disabled': _ctx.disabled
        }, "c-loyaltyCard"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_loyalty_custom_icon, {
          icon: _ctx.icon,
          class: "c-icon--medium c-icon--currentColor"
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.subtitle), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "actions")
    ]),
    _renderSlot(_ctx.$slots, "content")
  ], 2))
}