import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f68e4c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "c-loader c-loader--small"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "c-loyaltyEarn-products"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bubble = _resolveComponent("bubble")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_icon = _resolveComponent("icon")!
  const _component_product_to_review = _resolveComponent("product-to-review")!
  const _component_loyalty_card = _resolveComponent("loyalty-card")!
  const _component_birthday_form = _resolveComponent("birthday-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isBirthdayUpdated)
      ? (_openBlock(), _createBlock(_component_bubble, {
          key: 0,
          content: _ctx.$t('Birthday saved'),
          status: "success"
        }, null, 8, ["content"]))
      : _createCommentVNode("", true),
    _createVNode(_component_loyalty_card, {
      key: _ctx.rule.earningRuleId,
      title: _ctx.rule.title,
      subtitle: _ctx.getRuleDescription(_ctx.rule),
      icon: _ctx.rule.icon,
      disabled: _ctx.isDisabled,
      layout: "inline"
    }, _createSlots({
      actions: _withCtx(() => [
        (_ctx.rule.action !== 'submit-review')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              disabled: _ctx.isDisabled,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.rule)), ["prevent"])),
              class: "c-button c-loyaltyModule-button"
            }, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : (_ctx.rule.isCompleted)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 1,
                      icon: "check",
                      class: "c-icon--xSmall"
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.buttonText), 1))
            ], 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleProductsPanel && _ctx.toggleProductsPanel(...args)), ["prevent"])),
              class: "c-loyaltyEarn-accordionButton"
            }, [
              _createVNode(_component_icon, {
                icon: _ctx.isProductsPanelVisible ? 'minus' : 'plus',
                class: "c-icon--small"
              }, null, 8, ["icon"])
            ]))
      ]),
      _: 2
    }, [
      (_ctx.rule.action === 'submit-review')
        ? {
            name: "content",
            fn: _withCtx(() => [
              (_ctx.isProductsPanelVisible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.remainingProductsToReview, (product) => {
                      return (_openBlock(), _createBlock(_component_product_to_review, {
                        key: product.productId,
                        product: product,
                        layout: "inline"
                      }, null, 8, ["product"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          }
        : undefined
    ]), 1032, ["title", "subtitle", "icon", "disabled"]),
    _createVNode(_component_modal, {
      isModalVisible: _ctx.birthdayModal.isVisible,
      onResponse: _ctx.birthdayModal.response,
      okeClass: "c-modal-body--small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_birthday_form, {
          onOnSave: _ctx.birthdayModal.response,
          existingBirthday: _ctx.existingBirthday
        }, null, 8, ["onOnSave", "existingBirthday"])
      ]),
      _: 1
    }, 8, ["isModalVisible", "onResponse"])
  ], 64))
}