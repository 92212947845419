const mediaBaseUrl = process.env.VUE_APP_TRANSCODED_MEDIA_DYNAMIC_BASE_URL;

export function getTranscodedImageUrl(
    dynamicKey: string | undefined,
    isSubscriberContent: boolean,
    width?: number,
    height?: number
): string | undefined {
    if (dynamicKey) {
        const pixelRatio = Math.round(window.devicePixelRatio) || 1;
        const subscriberContentPathPart = isSubscriberContent ? '/subscriber_content' : '';

        if ((!width && !height) || (width && width < 0) || (height && height < 0)) {
            return `${mediaBaseUrl}${subscriberContentPathPart}/images/${dynamicKey}.jpg?d=1600x1600`;
        }
        else {
            return `${mediaBaseUrl}${subscriberContentPathPart}/images/${dynamicKey}.jpg?d=${
                width ? Math.floor(width * pixelRatio) : ''
            }x${height ? Math.floor(height * pixelRatio) : ''}${width && height ? '&crop=center' : ''}`;
        }
    }
}
