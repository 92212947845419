import { LoyaltyAPIPublicAuth, LoyaltyEarningRule } from '@okendo/reviews-common';

import { get, post, put } from './api';
import store from '@/store';
import { attemptUserAuth } from './loyaltyInitialisationUtil';
import { isAuthenticated } from './loyaltyUtils';

const appBaseUrl = process.env.VUE_APP_API_BASE_URL;

export function getEarningRules(): Promise<LoyaltyAPIPublicAuth.EarningRules.Get.Response> {
    return authenticatedGet('earning_rules');
}

export function getRedemptionRules(): Promise<LoyaltyAPIPublicAuth.RedemptionRules.Get.Response> {
    return authenticatedGet('redemption_rules');
}

export function getLoyaltyCustomerDetails(): Promise<LoyaltyAPIPublicAuth.CustomerDetails.Get.Response> {
    return authenticatedGet('customer_details');
}

export function getLoyaltyCustomerCoupons(): Promise<LoyaltyAPIPublicAuth.Coupons.Get.Response> {
    return authenticatedGet('coupons');
}

export function getLoyaltyCustomerHistory(): Promise<LoyaltyAPIPublicAuth.Transactions.Get.Response> {
    return authenticatedGet('transactions');
}

export async function getMore<T>(nextUrl: string, limit?: number): Promise<T> {
    const loadMoreUrl = limit ? nextUrl.replace(/limit=[0-9]*/i, `limit=${limit}`) : nextUrl;
    return get<T>(`${appBaseUrl}${loadMoreUrl}`, await getAuthHeader());
}

export function postActivateCustomer(): Promise<LoyaltyAPIPublicAuth.CustomerActivation.Post.Response> {
    return authenticatedPost('customer_activation', {});
}

export function postTriggerEarningRule(earningRuleId: LoyaltyEarningRule.Id): Promise<LoyaltyAPIPublicAuth.EarningRules.EarningRuleId.Trigger.Post.Response> {
    return authenticatedPost(`earning_rules/${earningRuleId}/trigger`, {});
}

export function getLoyaltyCustomerSpend(): Promise<LoyaltyAPIPublicAuth.CustomerSpend.Get.Response> {
    return authenticatedGet('customer_spend');
}

export function postRedemption(request: LoyaltyAPIPublicAuth.Redemptions.Post.Request): Promise<LoyaltyAPIPublicAuth.Redemptions.Post.Response> {
    return authenticatedPost('redemptions', request);
}

export function putCustomerBirthday(request: LoyaltyAPIPublicAuth.CustomerBirthday.Put.Request): Promise<void> {
    return authenticatedPut('customer_birthday', request);
}

async function authenticatedPost<T>(endpoint: string, request: unknown): Promise<T> {
    return post(`${getAuthenticatedLoyaltyUrl()}/${endpoint}`, request, await getAuthHeader());
}

async function authenticatedPut<T>(endpoint: string, request: unknown): Promise<T> {
    return put(`${getAuthenticatedLoyaltyUrl()}/${endpoint}`, request, await getAuthHeader());
}

async function authenticatedGet<T>(endpoint: string): Promise<T> {
    return get(`${getAuthenticatedLoyaltyUrl()}/${endpoint}`, await getAuthHeader());
}

function getAuthenticatedLoyaltyUrl(): string {
    return `${appBaseUrl}/loyalty`;
}

async function getAuthHeader(): Promise<{ headers: { Authorization: string } }> {
    if (!isAuthenticated()) {
        await attemptUserAuth();
    }

    return { headers: { Authorization: `Bearer ${store.state.loyaltyAuth.token}` } };
}

export type LoyaltyCustomerSpend = LoyaltyAPIPublicAuth.CustomerSpend.Get.Response;
