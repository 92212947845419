import type { DateString } from '@okendo/reviews-common';

export function formatDate(dateString: DateString): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        dateStyle: 'long'
    };
    const formatter = new Intl.DateTimeFormat(undefined, options);
    return formatter.format(date);
}
